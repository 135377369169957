var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: "",
                    default: "today",
                    label: "설비 예방점검일",
                    name: "recommDt",
                  },
                  model: {
                    value: _vm.searchParam.recommDt,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "recommDt", $$v)
                    },
                    expression: "searchParam.recommDt",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "작업지시 추천 목록(일상점검 결과)",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "daydata"
                    ? [
                        _c(
                          "span",
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("toThousandFilter")(props.row.daydata)
                                ) +
                                " "
                            ),
                            _vm.getDiffData(
                              props.row.daydata,
                              props.row.checkStandard
                            )
                              ? _c("q-badge", {
                                  attrs: { color: "blue", label: "UBM 이상" },
                                })
                              : _vm.getDiffData(
                                  props.row.daydata,
                                  props.row.gaugeExecUcl
                                )
                              ? _c("q-badge", {
                                  attrs: { color: "red", label: "실행 상한값" },
                                })
                              : _vm.getDiffData(
                                  props.row.gaugeExecLcl,
                                  props.row.daydata
                                )
                              ? _c("q-badge", {
                                  attrs: { color: "red", label: "실행 하한값" },
                                })
                              : _vm.getDiffData(
                                  props.row.daydata,
                                  props.row.gaugeWarnUcl
                                )
                              ? _c("q-badge", {
                                  attrs: {
                                    color: "orange",
                                    label: "경고 상한값",
                                  },
                                })
                              : _vm.getDiffData(
                                  props.row.gaugeWarnLcl,
                                  props.row.daydata
                                )
                              ? _c("q-badge", {
                                  attrs: {
                                    color: "orange",
                                    label: "경고 하한값",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  col.name === "workorder"
                    ? [
                        _c(
                          "q-chip",
                          {
                            attrs: {
                              color: "primary",
                              "text-color": "white",
                              clickable: true,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.regWorkPlan(props.row)
                              },
                            },
                          },
                          [_vm._v(" 작업지시작성 ")]
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }