<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            required
            default="today"
            label="설비 예방점검일"
            name="recommDt"
            v-model="searchParam.recommDt"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="작업지시 추천 목록(일상점검 결과)"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='daydata'">
          <span>
            {{props.row.daydata | toThousandFilter}}
            <q-badge v-if="getDiffData(props.row.daydata, props.row.checkStandard)" color="blue" label="UBM 이상" />
            <q-badge v-else-if="getDiffData(props.row.daydata, props.row.gaugeExecUcl)" color="red" label="실행 상한값" />
            <q-badge v-else-if="getDiffData(props.row.gaugeExecLcl, props.row.daydata)" color="red" label="실행 하한값" />
            <q-badge v-else-if="getDiffData(props.row.daydata, props.row.gaugeWarnUcl)" color="orange" label="경고 상한값" />
            <q-badge v-else-if="getDiffData(props.row.gaugeWarnLcl, props.row.daydata)" color="orange" label="경고 하한값" />
          </span>
        </template>
        <template v-if="col.name==='workorder'">
          <q-chip
            color="primary"
            text-color="white"
            :clickable="true"
            @click="regWorkPlan(props.row)">
            작업지시작성
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'preventive-maintenance-recom',
  data() {
    return {
      searchParam: {
        plantCd: null,
        recommDt: '',
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'recommDate',
            field: 'recommDate',
            label: '점검일',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '대상설비',
            align: 'left',
            sortable: true,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'checkItemPartName',
            field: 'checkItemPartName',
            label: '점검항목 유형',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'checkStandard',
            field: 'checkStandard',
            label: 'UBM 기준값',
            align: 'center',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'gaugeWarnUcl',
            field: 'gaugeWarnUcl',
            label: '경고상한값',
            align: 'center',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'gaugeWarnLcl',
            field: 'gaugeWarnLcl',
            label: '경고하한값',
            align: 'center',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'gaugeExecUcl',
            field: 'gaugeExecUcl',
            label: '실행상한값',
            align: 'center',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'gaugeExecLcl',
            field: 'gaugeExecLcl',
            label: '실행하한값',
            align: 'center',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'daydata',
            field: 'daydata',
            label: '점검 측정치',
            type: 'custom',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'workorder',
            field: 'workorder',
            type: 'custom',
            label: '작업지시/계획',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.pm.mst.recomm.url;
      this.getList();
    },
    getList() {
      if (this.searchParam.recommDt) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비 예방점검일을 선택하세요', // 기능위치를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      }
    },
    getDiffData(val1, val2) {
      if (parseFloat(val1) >= parseFloat(val2)) {
        return true;
      } else {
        return false;
      }
    },
    regWorkPlan(_row) {
      let titletxt = '';
      if (this.getDiffData(_row.daydata, _row.checkStandard)) {
        titletxt = 'UBM 이상';
      } else if (this.getDiffData(_row.daydata, _row.gaugeExecUcl)) {
        titletxt = '실행 상한값';
      } else if (this.getDiffData(_row.gaugeExecLcl, _row.daydata)) {
        titletxt = '실실행 하한값';
      } else if (this.getDiffData(_row.daydata, _row.gaugeWarnUcl)) {
        titletxt = '경고 상한값';
      } else if (this.getDiffData(_row.gaugeWarnLcl, _row.daydata)) {
        titletxt = '경고 하한값';
      }

      this.popupOptions.title = '작업지시(계획) 작성';
      this.popupOptions.param = {
        plantCd: _row.plantCd,
        newEquipmentCd: _row.equipmentCd,
        newTitle: _row.equipmentName + ' (' + _row.checkItemName + ')' + titletxt,
        newWoWorkTypeCd: 'WWTC000001', // 작업유형 (PM)
        woWorkPlanStepCd: 'WWPSC00001',
        copyFlag: 'N',
      };
      this.popupOptions.target = () => import(`${'@/pages/wod/plan/workOrderPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
